import {
  primaryTextColor,
  defaultMobileFontSize,
  defaultDesktopFontSize,
  container,
  getCalcRem,
} from "src/assets/jss/material-kit-react.js"
import defaultComponentStyles from "src/assets/jss/material-kit-react/defaultComponentStyles"

const contactSectionStyles = theme => ({
  ...defaultComponentStyles,
  contactSection: {
    ...container,
    "& .cardBody": {
      "& .MuiGrid-item": {
        marginBottom: theme.spacing(4),
        [theme.breakpoints.up("md")]: {
          marginBottom: theme.spacing(6),
        },
      },
      "& .services-field-set .MuiFormControlLabel-root": {
        marginLeft: theme.spacing(1),
        width: "100%",
      },
      "& .contact-submit": {
        marginTop: theme.spacing(2),
      },
    },
  },
})

export default contactSectionStyles
