import React, { useState, useRef } from "react"
import axios from "axios"
import { makeStyles } from "@material-ui/core/styles"
import classNames from "classnames"

// core components
import Card from "src/components/Card/Card.js"
import CardBody from "src/components/Card/CardBody.js"
import CardHeader from "src/components/Card/CardHeader.js"
import GridContainer from "src/components/Grid/GridContainer.js"
import GridItem from "src/components/Grid/GridItem.js"
import CustomButton from "src/components/CustomButton"

import {
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"

import styles from "src/assets/jss/material-kit-react/views/contactPage/sections/contactSectionStyles"
import cardStyles from "src/assets/jss/material-kit-react/components/cardStyle"
const useStyles = makeStyles(styles)
const useCardStyles = makeStyles(cardStyles)

const ContactSection = ({ pageContent, globalState }) => {
  const {
    headerText_page,
    text_contactInfoSubheader,
    text_contactFormSubheader,
    text_contactInstructions,
    text_informationSectionNotice,
    text_address,
    text_emailAddress,
    text_phone,
    boolean_displayAddressAsMapLink,
  } = pageContent
  const classes = useStyles()
  const cardClasses = useCardStyles()

  const formRef = useRef()
  const emailFieldRef = useRef()
  const nameFieldRef = useRef()
  const phoneFieldRef = useRef()
  const messageFieldRef = useRef()

  const [state, setState] = useState({
    checkedBuy: false,
    checkedSell: false,
    checkedBuySell: false,
    checkedRenovations: false,
    checkedLeasing: false,
  })

  const handleChange = event => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  const submitForm = event => {
    if (!globalState.isBrowser) return
    event.preventDefault()

    const submission = {
      ...state,
      email: emailFieldRef.current.value,
      name: nameFieldRef.current.value,
      phone: phoneFieldRef.current.value,
      message: messageFieldRef.current.value,
    }
    axios.post("https://submit-form.com/QhzlVpu7", submission).then(res => {
      formRef.current.reset()
      setState({
        checkedBuy: false,
        checkedSell: false,
        checkedBuySell: false,
        checkedRenovations: false,
        checkedLeasing: false,
      })
    })
  }

  const address = () => {
    if (boolean_displayAddressAsMapLink) {
      return (
        <a
          href={"https://maps.google.com/?q" + encodeURIComponent(text_address)}
          title="Open address in map application"
          data-analytics-label="Contact Us section open address in map application"
          target="_blank"
          rel="noopener"
        >
          <a
            href={
              "https://maps.apple.com/maps?q=" +
              encodeURIComponent(text_address)
            }
            title="Open address in map application"
            data-analytics-label="Contact Us section open address in map application"
            target="_blank"
            rel="noopener"
          >
            {text_address}
          </a>
        </a>
      )
    } else {
      return text_address
    }
  }

  return (
    <Card className={classNames(classes.contactSection)}>
      <CardHeader color="primary">{headerText_page}</CardHeader>

      <CardBody
        className={classNames(
          "cardBody",
          classes.cardBody,
          cardClasses.cardBody
        )}
      >
        <GridContainer className="padded" direction="column">
          <GridItem className="contact-form-wrapper" xs={12} sm={6}>
            <Typography
              variant="h4"
              className="pageSubheader-root"
              gutterBottom
            >
              {text_contactFormSubheader}
            </Typography>
            <div
              className="MuiTypography-body1 MuiTypography-gutterBottom"
              dangerouslySetInnerHTML={{
                __html: text_contactInstructions.childMarkdownRemark.html,
              }}
            />
            <form
              className="contact-form"
              noValidate
              autoComplete="off"
              ref={formRef}
            >
              <div className="field-set services-field-set">
                <label
                  className="MuiFormLabel-colorSecondary MuiInputLabel-root"
                  data-shrink="false"
                  id="services-field-label"
                >
                  Services:
                </label>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkedBuy"
                      onChange={handleChange}
                      checked={state.checkedBuy}
                    />
                  }
                  label="Buy"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkedSell"
                      onChange={handleChange}
                      checked={state.checkedSell}
                    />
                  }
                  label="Sell"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkedBuySell"
                      onChange={handleChange}
                      checked={state.checkedBuySell}
                    />
                  }
                  label="Buy & Sell"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkedRenovations"
                      onChange={handleChange}
                      checked={state.checkedRenovations}
                    />
                  }
                  label="Renovations"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="checkedLeasing"
                      onChange={handleChange}
                      checked={state.checkedLeasing}
                    />
                  }
                  label="Leasing"
                />
              </div>
              <div className="field-set">
                <TextField
                  id="email-field"
                  label="Email Address"
                  required
                  fullWidth
                  inputRef={emailFieldRef}
                  color="secondary"
                />
              </div>
              <div className="field-set">
                <TextField
                  id="name-field"
                  label="Name"
                  fullWidth
                  inputRef={nameFieldRef}
                  color="secondary"
                  required
                />
              </div>
              <div className="field-set">
                <TextField
                  id="phone-field"
                  label="Phone"
                  fullWidth
                  inputRef={phoneFieldRef}
                  color="secondary"
                />
              </div>
              <div className="field-set">
                <TextField
                  id="message-field"
                  label="Message"
                  multiline
                  rowsMax="6"
                  fullWidth
                  inputRef={messageFieldRef}
                  required
                  color="secondary"
                />
              </div>
              <CustomButton
                color="primary"
                className="contact-submit"
                onClick={submitForm}
              >
                Send
              </CustomButton>
            </form>
          </GridItem>
          {(text_address || text_phone || text_emailAddress) && (
            <GridItem className="contact-info-wrapper" xs={12} sm={8} md={6}>
              <Typography
                variant="h4"
                className="pageSubheader-root"
                gutterBottom
              >
                {text_contactInfoSubheader}
              </Typography>
              {text_phone && (
                <Typography variant="body1" gutterBottom>
                  <span>Cell: </span>
                  <a
                    href={"tel:" + text_phone}
                    title={"Open in phone application and call " + text_phone}
                    data-analytics-label="Contact Us section link to call Homes by Kiwi"
                    target="_blank"
                    rel="noopener"
                  >
                    {text_phone}
                  </a>
                </Typography>
              )}
              {text_emailAddress && (
                <Typography variant="body1" gutterBottom>
                  <a
                    href={"mailto:" + text_emailAddress}
                    title={"Open in mail application with preaddressed email"}
                    data-analytics-label="Contact Us section link to email Homes by Kiwi"
                    target="_blank"
                    rel="noopener"
                  >
                    {text_emailAddress}
                  </a>
                </Typography>
              )}

              {text_address && (
                <Typography variant="body1" gutterBottom>
                  {address()}
                </Typography>
              )}
              <div
                className="MuiTypography-body1 MuiTypography-gutterBottom"
                dangerouslySetInnerHTML={{
                  __html:
                    text_informationSectionNotice.childMarkdownRemark.html,
                }}
              />
            </GridItem>
          )}
        </GridContainer>
      </CardBody>
    </Card>
  )
}

export default ContactSection
