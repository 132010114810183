import defaultPageStyles from "src/assets/jss/material-kit-react/defaultPageStyles"

const contactPageStyles = theme => ({
  body: {
    ...defaultPageStyles,
    height: "auto",
    "& .cardBody": {
    }
  }
})

export default contactPageStyles
