/*eslint no-unused-vars: ["error", { "varsIgnorePattern": "[iF]ragment" }]*/

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SiteVariablesFragment from "src/components/QueryFragments"
import { ContactPageFragment } from "src/components/QueryFragments"

import "src/assets/scss/material-kit-react.scss"

import ContactPageView from "../views/ContactPage"

const ContactPage = ({ location }) => {
  const {
    contentfulWebsiteGlobalVariables,
    contentfulPageContact
  } = useStaticQuery(
    graphql`
      query {
        contentfulWebsiteGlobalVariables {
          ...SiteVariablesFragment
        }
        contentfulPageContact {
          ...ContactPageFragment
        }
      }
    `
  )

  return (
    <ContactPageView
      siteVariables={contentfulWebsiteGlobalVariables}
      pageContent={contentfulPageContact}
      location={location}
    />
  )
}

export default ContactPage
